var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row step-column-wrap" }, [
    _c(
      "div",
      { staticClass: "large-4 medium-5 small-12 columns sample-section" },
      [
        _c("div", { staticClass: "small-12 text-center" }, [
          _c(
            "a",
            {
              class: { loading: _vm.isLoadingSamples },
              style: { opacity: _vm.isLoadingSamples ? 1 : 0 },
              attrs: { id: "blog-load-more" },
            },
            [_c("span", { staticClass: "more-blog-loading-icon" })]
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "medium-block-grid-2",
            attrs: { id: "form-products" },
          },
          _vm._l(_vm.cart, function (cartItem) {
            return _c("li", { key: cartItem.id, staticClass: "form-product" }, [
              _c(
                "a",
                {
                  staticClass: "item-in-cart",
                  attrs: { role: "button", "data-sample": cartItem.id },
                },
                [
                  _c(
                    "div",
                    { staticClass: "columns small-12 image-sample-cart" },
                    [
                      _c("span", {
                        staticClass: "remove-icon",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("remove-sample", cartItem)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass:
                          "attachment-medium_square size-medium_square",
                        attrs: {
                          width: "400",
                          height: "400",
                          src: cartItem.image,
                          alt: "",
                          srcset: cartItem.image + " 400w",
                          sizes: "(max-width: 400px) 100vw, 400px",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "columns small-12 sample-details" },
                    [
                      _c("span", [_vm._v(_vm._s(cartItem.product.title))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(cartItem.title))]),
                      _vm._v(" "),
                      cartItem.dimensions.width
                        ? _c("span", [
                            _vm._v(
                              _vm._s(cartItem.dimensions.width) +
                                '" x ' +
                                _vm._s(cartItem.dimensions.length) +
                                '"'
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ])
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", { staticClass: "small-12 text-center" }, [
          _c(
            "a",
            {
              class: { loading: _vm.isLoading },
              style: {
                display: _vm.isLoading ? "block" : "none",
                opacity: _vm.isLoading ? 1 : 0,
              },
              attrs: { id: "blog-load-more" },
            },
            [
              _c("h5", [_vm._v("Submitting Sample Reqest...")]),
              _vm._v(" "),
              _c("span", { staticClass: "more-blog-loading-icon" }),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          {
            staticClass:
              "large-8 medium-7 small-12 columns checkout-form-section",
          },
          [
            _vm.error.hasError
              ? _c("div", { staticClass: "column small-12" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "heading-title error-text",
                      staticStyle: { display: "block" },
                    },
                    [_vm._v(_vm._s(_vm.error.message))]
                  ),
                  _vm._v(" "),
                  _c("hr"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("ValidationObserver", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ invalid }) {
                      return [
                        _c(
                          "form",
                          {
                            staticClass: "column",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.handleFormSubmit.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "syrup-field hide" }, [
                              _c("textarea", {
                                attrs: {
                                  name: "samples",
                                  id: "form-products-output",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "form-products-output" } },
                                [_vm._v("Samples")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "columns medium-6" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "First Name",
                                      autocomplete: "given-name",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "syrup-field",
                                                  class: classes,
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkoutForm
                                                            .shippingAddress
                                                            .firstName,
                                                        expression:
                                                          "checkoutForm.shippingAddress.firstName",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      id: "firstname",
                                                      placeholder:
                                                        "First Name*",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.checkoutForm
                                                          .shippingAddress
                                                          .firstName,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.checkoutForm
                                                            .shippingAddress,
                                                          "firstName",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "firstname",
                                                      },
                                                    },
                                                    [_vm._v("First Name")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "columns medium-6" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Last Name",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "syrup-field",
                                                  class: classes,
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkoutForm
                                                            .shippingAddress
                                                            .lastName,
                                                        expression:
                                                          "checkoutForm.shippingAddress.lastName",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      id: "lastname",
                                                      autocomplete:
                                                        "family-name",
                                                      placeholder: "Last Name*",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.checkoutForm
                                                          .shippingAddress
                                                          .lastName,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.checkoutForm
                                                            .shippingAddress,
                                                          "lastName",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "lastname",
                                                      },
                                                    },
                                                    [_vm._v("Last Name")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "columns medium-6" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Email",
                                      rules: "required|email",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "syrup-field",
                                                  class: classes,
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkoutForm
                                                            .fields.email,
                                                        expression:
                                                          "checkoutForm.fields.email",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "email",
                                                      id: "email",
                                                      autocomplete: "email",
                                                      placeholder: "Email*",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.checkoutForm.fields
                                                          .email,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.checkoutForm
                                                            .fields,
                                                          "email",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "email" } },
                                                    [_vm._v("Email")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "columns medium-6" },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Phone", rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "syrup-field",
                                                  class: classes,
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkoutForm
                                                            .shippingAddress
                                                            .phone,
                                                        expression:
                                                          "checkoutForm.shippingAddress.phone",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "tel",
                                                      autocomplete: "tel",
                                                      id: "phone",
                                                      placeholder: "Phone*",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.checkoutForm
                                                          .shippingAddress
                                                          .phone,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.checkoutForm
                                                            .shippingAddress,
                                                          "phone",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "phone" } },
                                                    [_vm._v("Phone")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "column small-12" }, [
                                _c("h6", { staticClass: "heading-title" }, [
                                  _vm._v("ADDRESS"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("validation-provider", {
                              attrs: {
                                name: "Street Address",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, classes }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "syrup-field",
                                            class: classes,
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.checkoutForm
                                                      .shippingAddress.address1,
                                                  expression:
                                                    "checkoutForm.shippingAddress.address1",
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                id: "street",
                                                autocomplete: "street-address",
                                                placeholder: "Street Address*",
                                                required: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.checkoutForm
                                                    .shippingAddress.address1,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.checkoutForm
                                                      .shippingAddress,
                                                    "address1",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { attrs: { for: "street" } },
                                              [_vm._v("Street Address")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "columns medium-4" },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "City", rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "syrup-field",
                                                  class: classes,
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkoutForm
                                                            .shippingAddress
                                                            .city,
                                                        expression:
                                                          "checkoutForm.shippingAddress.city",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      id: "city",
                                                      autocomplete:
                                                        "address-level2",
                                                      placeholder: "City*",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.checkoutForm
                                                          .shippingAddress.city,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.checkoutForm
                                                            .shippingAddress,
                                                          "city",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "city" } },
                                                    [_vm._v("City")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "columns medium-8" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "columns small-7" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "State",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                errors,
                                                classes,
                                              }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "syrup-field",
                                                      class: classes,
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.checkoutForm
                                                                  .shippingAddress
                                                                  .stateId,
                                                              expression:
                                                                "checkoutForm.shippingAddress.stateId",
                                                            },
                                                          ],
                                                          attrs: {
                                                            required: "",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.checkoutForm
                                                                  .shippingAddress,
                                                                "stateId",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                              },
                                                            },
                                                            [_vm._v("State*")]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            _vm.states,
                                                            function (
                                                              stateId,
                                                              stateName
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: stateName,
                                                                  domProps: {
                                                                    value:
                                                                      stateId,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      stateName
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "columns small-5" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Zip Code",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                errors,
                                                classes,
                                              }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "syrup-field",
                                                      class: classes,
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.checkoutForm
                                                                .shippingAddress
                                                                .zipCode,
                                                            expression:
                                                              "checkoutForm.shippingAddress.zipCode",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "text",
                                                          id: "zip",
                                                          autocomplete:
                                                            "postal-code",
                                                          placeholder: "ZIP*",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.checkoutForm
                                                              .shippingAddress
                                                              .zipCode,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.checkoutForm
                                                                .shippingAddress,
                                                              "zipCode",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: { for: "zip" },
                                                        },
                                                        [_vm._v("ZIP")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "columns" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "expedited-fieldset nydree-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.isExpeditedShipping,
                                          expression: "isExpeditedShipping",
                                        },
                                      ],
                                      attrs: {
                                        id: "expedited",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.isExpeditedShipping
                                        )
                                          ? _vm._i(
                                              _vm.isExpeditedShipping,
                                              null
                                            ) > -1
                                          : _vm.isExpeditedShipping,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.isExpeditedShipping,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.isExpeditedShipping =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.isExpeditedShipping = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.isExpeditedShipping = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { attrs: { for: "expedited" } },
                                      [_vm._v("Request Expedited Shipping")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "columns" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "commercial-fieldset nydree-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.isBusinessAddress,
                                          expression: "isBusinessAddress",
                                        },
                                      ],
                                      attrs: {
                                        id: "commercial",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.isBusinessAddress
                                        )
                                          ? _vm._i(
                                              _vm.isBusinessAddress,
                                              null
                                            ) > -1
                                          : _vm.isBusinessAddress,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.isBusinessAddress,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.isBusinessAddress =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.isBusinessAddress = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.isBusinessAddress = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { attrs: { for: "commercial" } },
                                      [_vm._v("This is a Business Address")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "column small-12" }, [
                                _c("h6", { staticClass: "heading-title" }, [
                                  _vm._v("PROJECT INFORMATION"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "syrup-field" }, [
                              _c(
                                "div",
                                { staticClass: "project-timeline-fieldset" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "project-timeline-heading" },
                                    [_vm._v("Project Timeline")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "medium-block-grid-3 medium-7",
                                    },
                                    [
                                      _c("li", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.checkoutForm.fields
                                                  .orderProjectTimeline,
                                              expression:
                                                "checkoutForm.fields.orderProjectTimeline",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            id: "months3",
                                            value: "3 Months",
                                            checked: "checked",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.checkoutForm.fields
                                                .orderProjectTimeline,
                                              "3 Months"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.checkoutForm.fields,
                                                "orderProjectTimeline",
                                                "3 Months"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "months3" } },
                                          [_vm._v("3 Months")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.checkoutForm.fields
                                                  .orderProjectTimeline,
                                              expression:
                                                "checkoutForm.fields.orderProjectTimeline",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            id: "months6",
                                            value: "6 Months",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.checkoutForm.fields
                                                .orderProjectTimeline,
                                              "6 Months"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.checkoutForm.fields,
                                                "orderProjectTimeline",
                                                "6 Months"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "months6" } },
                                          [_vm._v("6 Months")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.checkoutForm.fields
                                                  .orderProjectTimeline,
                                              expression:
                                                "checkoutForm.fields.orderProjectTimeline",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            id: "months9",
                                            value: "9+ Months",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.checkoutForm.fields
                                                .orderProjectTimeline,
                                              "9+ Months"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.checkoutForm.fields,
                                                "orderProjectTimeline",
                                                "9+ Months"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "months9" } },
                                          [_vm._v("9+ Months")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.checkoutForm.fields.orderAddressIsBusiness
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "syrup-field",
                                    attrs: { id: "company-contain" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.checkoutForm.fields
                                              .orderCompanyName,
                                          expression:
                                            "checkoutForm.fields.orderCompanyName",
                                        },
                                      ],
                                      attrs: {
                                        type: "text",
                                        id: "company",
                                        placeholder: "Company Name",
                                      },
                                      domProps: {
                                        value:
                                          _vm.checkoutForm.fields
                                            .orderCompanyName,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.checkoutForm.fields,
                                            "orderCompanyName",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: "company" } }, [
                                      _vm._v("Company Name"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "columns medium-6" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Project Name",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "syrup-field",
                                                  class: classes,
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkoutForm
                                                            .fields
                                                            .orderProjectName,
                                                        expression:
                                                          "checkoutForm.fields.orderProjectName",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      id: "projectname",
                                                      placeholder:
                                                        "Project Name*",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.checkoutForm.fields
                                                          .orderProjectName,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.checkoutForm
                                                            .fields,
                                                          "orderProjectName",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "projectname",
                                                      },
                                                    },
                                                    [_vm._v("Project Name")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "columns medium-6" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Project Category",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, classes }) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "syrup-field" },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.checkoutForm
                                                              .fields
                                                              .orderProjectCategory,
                                                          expression:
                                                            "checkoutForm.fields.orderProjectCategory",
                                                        },
                                                      ],
                                                      attrs: { required: "" },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.checkoutForm
                                                              .fields,
                                                            "orderProjectCategory",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "",
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Project Category*"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.projectCategories,
                                                        function (category) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: category.value,
                                                              domProps: {
                                                                value:
                                                                  category.value,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  category.label
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "syrup-field" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.checkoutForm.fields
                                        .orderProjectComments,
                                    expression:
                                      "checkoutForm.fields.orderProjectComments",
                                  },
                                ],
                                attrs: {
                                  rows: "8",
                                  id: "projectcomments",
                                  placeholder: "",
                                },
                                domProps: {
                                  value:
                                    _vm.checkoutForm.fields
                                      .orderProjectComments,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.checkoutForm.fields,
                                      "orderProjectComments",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "projectcomments" } },
                                [_vm._v("Project Comments")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-right row" }, [
                              _c("div", { staticClass: "column small-12" }, [
                                _vm.error.hasError
                                  ? _c(
                                      "h6",
                                      {
                                        staticClass: "error-text",
                                        staticStyle: { display: "block" },
                                      },
                                      [_vm._v(_vm._s(_vm.error.message))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "button naked",
                                  attrs: {
                                    type: "submit",
                                    disabled: invalid,
                                    value: "SUBMIT",
                                  },
                                }),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                4268971916
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column small-12" }, [
      _c("h6", { staticClass: "heading-title icon" }, [
        _c("i", { staticClass: "icon-cart-sample" }),
        _vm._v(" SAMPLES IN CART"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column small-12" }, [
      _c("h6", { staticClass: "heading-title" }, [
        _vm._v("CONTACT INFORMATION"),
      ]),
      _vm._v(" "),
      _c("p", [_c("span", [_vm._v("*")]), _vm._v(" required field")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }